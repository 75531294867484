/* :root {
  --dark-background: #01061C;
  --light-text: #ADDDFF;
} */

:root {
  --dark-background: #000000;
  --light-text: #FFFFFF;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.blue-image {
  filter: brightness(0) invert(1);
}

.MuiList-root {
  color: var(--light-text);
  background-color: var(--dark-background);
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  background-color: var(--dark-background) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stagger-visualizer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 17rem;
  height: 17rem;
}

.stagger-visualizer div {
  /*position: absolute;*/
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--light-text);
  background-color: var(--light-text);
}

.about-line {
  width: 2px;
}

.dark-background {
  box-shadow: 0 0 0 100vmax var(--dark-background);
  clip-path: inset(0 -100vmax); 
  /*scroll-snap-align: start;*/
}

.image-border {
  --offset:3px;
  position: relative;
  background: var(--light-text);
  border-radius: 50px;
  height: 300px;
  width: 400px;
  max-width: 100%;
  overflow:hidden;
}

.image-border::before {
  content: '';
  background: conic-gradient(transparent 270deg, white, transparent);
  position: absolute;
  top: 50%;
  left: 50%;
  transform : translate(-50%, -50%);
  aspect-ratio: 1;
  width: 100%;
  animation: rotate 2s linear infinite;
}

.image-border::after {
  content: '';
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));
}

.image-border img {
  background: transparent;
  color: white;
  font-size: 1.5rem;
  position:absolute;
  inset: 0;
  z-index:10;
  padding: 1.5rem;
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
  }
  to {
    transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
  }
}

.portfolio-border span{
  position: absolute;
  display: block;
  background: var(--light-text);
}

.portfolio-border h6{
  transform: scaleY(0);
  transition: transform 0.5s;
}

.portfolio-border:hover h6{
  transform-origin: top;
  transform: scaleY(1);
  transition-delay: 0.8s;
}

.portfolio-border span:nth-child(1){
  left:0;
  top: 0;
  width: 50.5%;
  height: 1.5px;
  transform: scaleX(0);
  transition: transform 0.5s;
}

.portfolio-border:hover span:nth-child(1){
  transform-origin: right;
  transform: scaleX(1);
}

.portfolio-border span:nth-child(2){
  right:0;
  top: 0;
  width: 50.5%;
  height: 1.5px;
  transform: scaleX(0);
  transition: transform 0.5s;
}

.portfolio-border:hover span:nth-child(2){
  transform-origin: left;
  transform: scaleX(1);
}

.portfolio-border span:nth-child(3){
  right:0;
  top: 0;
  width: 1.5px;
  height: 100%;
  transform: scaleY(0);
  transition: transform 0.5s;
  transition-delay: 0.4s;
}

.portfolio-border:hover span:nth-child(3){
  transform-origin: top;
  transform: scaleY(1);
}

.portfolio-border span:nth-child(4){
  left:0;
  top: 0;
  width: 1.5px;
  height: 100%;
  transform: scaleY(0);
  transition: transform 0.5s;
  transition-delay: 0.4s;
}

.portfolio-border:hover span:nth-child(4){
  transform-origin: top;
  transform: scaleY(1);
}

.portfolio-border span:nth-child(5){
  left:0;
  bottom: 0;
  width: 50.5%;
  height: 1.5px;
  transform: scaleX(0);
  transition: transform 0.5s;
  transition-delay: 0.8s;
}

.portfolio-border:hover span:nth-child(5){
  transform-origin: left;
  transform: scaleX(1);
}

.portfolio-border span:nth-child(6){
  right:0;
  bottom: 0;
  width: 50.5%;
  height: 1.5px;
  transform: scaleX(0);
  transition: transform 0.5s;
  transition-delay: 0.8s;
}

.portfolio-border:hover span:nth-child(6){
  transform-origin: right;
  transform: scaleX(1);
}

/* Define keyframe animation */
@keyframes fade {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
}

/* Apply animation to the logo */
.logo {
  animation: fade 1.5s ease-in-out infinite;
}
